export const images_ftp = "https://cvchecker-ai.com/data/cvchecker_photos";
export const placeholders = {
  positives: [
    "Unlock the full analysis by offering me a coffee ! Here is some French poetry to make you wait for the payment to be processed: Toi, t'es bon qu'à planer. Ouais, je sens t'as l'seum, j'ai la boca. ",
    "Entre nous y a un fossé. Toi, t'es bon qu'à faire la mala. Bébé fait du sale, allô, allô, allô (allô). Million d'dollars, bébé tu vaux ça (ça). - Aya Nakamura, Pookie",
  ],
  negatives: [
    "Unlock the full analysis by offering me a coffee ! Here is some French poetry to make you wait for the payment to be processed:",
    "A un moment Mohamed tu viens d'en faire une donc tu t'abstiens, on parle que tu vas dire. Il intéresse personne aussi dans la presse aussi.",
    "Il faut sachez qu’il s’est pris des râteaux phénoménales par nabilla, il a dit, bien sûr que oui, que il a voulu essayer de faire des paparazailles aussi essayer de savoir, à un moment donné il faut qu’il arrête aussi. - Amélie Neten",
  ],
};
